import React, { useState, useEffect, useRef } from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"

import { useOnLoadImages } from "../hooks/useOnLoadImages"
import Preloader from "../components/preloader"
import ScrollTop from "../components/scrollTop"
import PhotoGrid from "../components/photo-grid"

const ProjectsPage: React.FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window.gtag) {
      window.gtag("event", "page_view", {
        page_title: "projects",
        page_path: "/projects",
        send_to: "G-LB7ZYCKNWC",
      })
    }
  }, [])
  const [showScroll, setShowScroll] = useState(false)
  const [projectsContainer, setProjectsContainer] = useState<HTMLDivElement>()
  const [currentFilter, setCurrentFilter] = useState<string>("ALL")
  const [items, setItems] = useState<PhotoGridItem[]>([])

  const wrapperRef = useRef<HTMLDivElement>(null)
  const imagesLoaded = useOnLoadImages(wrapperRef)

  useEffect(() => {
    onFilterClick("ALL")
  }, [])

  const checkScrollTop = () => {
    setProjectsContainer(
      document.getElementsByClassName("projects-container")[0] as HTMLDivElement
    )
    if (projectsContainer) {
      if (!showScroll && projectsContainer.scrollTop > 600) {
        setShowScroll(true)
      } else if (showScroll && projectsContainer.scrollTop <= 600) {
        setShowScroll(false)
      }
    }
  }

  const scrollTop = () => {
    if (projectsContainer)
      projectsContainer.scrollTo({ top: 0, behavior: "smooth" })
  }

  const data = useStaticQuery(graphql`
    query {
      projects: allPrismicProject(
        sort: { fields: data___project_date, order: DESC }
      ) {
        nodes {
          uid
          data {
            name
            city
            country
            thumbnail_image {
              url(imgixParams: { width: 500 })
              alt
            }
            thumbnail_hover_image {
              url(imgixParams: { width: 500 })
              alt
            }
            categories {
              category {
                document {
                  ... on PrismicProjectCategory {
                    data {
                      category
                    }
                  }
                }
              }
            }
          }
        }
      }
      categories: allPrismicProjectCategory(sort: { order: ASC, fields: uid }) {
        nodes {
          uid
          data {
            category
          }
        }
      }
    }
  `)
  const categories = data.categories.nodes.map(node => node.data.category)
  //add ALL category
  categories.unshift("ALL")
  const fetchedProjects = data.projects.nodes.map((node, i) => {
    return {
      id: i,
      urlSlug: node.uid,
      name: node.data.name,
      city: node.data.city,
      country: node.data.country,
      imageUrl: node.data.thumbnail_image.url,
      imageAlt: node.data.thumbnail_image.alt || "",
      hoverImageUrl: node.data.thumbnail_hover_image?.url || "",
      hoverImageAlt: node.data.thumbnail_hover_image?.alt || "",
      tags: node.data.categories.map(category => {
        return category.category.document
          ? category.category.document.data.category
          : ""
      }),
      show: true,
    }
  })

  const filters = categories

  const filterProjectsByTag = (tag: string) => {
    tag = tag.toUpperCase()
    if (tag === "ALL") {
      setItems(fetchedProjects)
      return
    }

    const filtered = fetchedProjects.filter(project => {
      return project.tags.includes(tag)
    })
    setItems(filtered)
  }

  const onFilterClick = filter => {
    setCurrentFilter(filter)
    filterProjectsByTag(filter)
  }

  return (
    <>
      {!imagesLoaded && <Preloader />}
      <Layout>
        <ScrollTop scrollTop={scrollTop} showScroll={showScroll} />
        <div className="flex flex-col content-wrapper" ref={wrapperRef}>
          <div className="flex flex-wrap justify-center flex-shrink-0 w-full px-1 mx-auto text-black sm:w-9/12 lg:w-8/12 xl:w-10/12">
            {filters.map((filter, i) => {
              return (
                <button
                  key={i}
                  className={`text-sm cursor-pointer sm:text-lg lg:text-xl hover:font-semibold focus:outline-none ${
                    currentFilter == filter ? "font-semibold" : ""
                  }`}
                  style={{ margin: "0.25rem 0.5rem" }}
                  onClick={() => onFilterClick(filter)}
                >
                  {filter}
                </button>
              )
            })}
          </div>

          <PhotoGrid items={items} onScroll={checkScrollTop} />
        </div>
      </Layout>
    </>
  )
}

export default ProjectsPage
