import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Link } from "gatsby"
import { isMobile } from "react-device-detect"
import clsx from "clsx"

type PhotoGridProps = {
  onScroll?: () => void
  items: PhotoGridItem[]
}

type PhotoGridItemProps = {
  item: PhotoGridItem
  active: boolean
  onItemMouseDown(): void
  onItemMouseUp(): void
}

const PhotoGridItem: React.FC<PhotoGridItemProps> = ({
  item,
  active,
  onItemMouseDown,
  onItemMouseUp,
}) => {
  let hoverComponent

  if (item.hoverImageUrl.length > 0) {
    hoverComponent = (
      <Link to={`/${item.urlSlug}`}>
        <div
          className={clsx(`
          w-full h-full absolute inset-0  bg-white z-10 text-shadow text-white text-base font-semibold flex flex-col justify-center text-center ${
            active ? "opacity-100" : "opacity-0"
          }
    `)}
        >
          <div className="relative">
            <img src={item.hoverImageUrl} />
            <div
              className="absolute w-full top-1/2 left-1/2"
              style={{ transform: "translate(-50%, -50%" }}
            >
              <div>{item.name && item.name.toUpperCase()}</div>
              <div>
                {item.city &&
                  item.country &&
                  `${item.city.toUpperCase()} // ${item.country.toUpperCase()}`}
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  } else {
    hoverComponent = (
      <Link to={`/${item.urlSlug}`}>
        <div
          className={clsx(`
    w-full h-full absolute inset-0 bg-opacity-80 lg:bg-opacity-75 bg-black z-10 text-shadow text-white text-base font-semibold flex flex-col justify-center text-center ${
      active ? "opacity-100" : "opacity-0"
    }
    `)}
        >
          <div>{item.name && item.name.toUpperCase()}</div>
          <div>
            {item.city &&
              item.country &&
              `${item.city.toUpperCase()} // ${item.country.toUpperCase()}`}
          </div>
        </div>
      </Link>
    )
  }
  return (
    <div
      onMouseEnter={() => onItemMouseDown()}
      onMouseLeave={() => onItemMouseUp()}
      onTouchStart={() => onItemMouseDown()}
      onTouchEnd={() => onItemMouseUp()}
    >
      <img src={item.imageUrl} className="" />
      {hoverComponent}
    </div>
  )
}

const PhotoGrid: React.FC<PhotoGridProps> = ({ items, onScroll }) => {
  const [activeItem, setActiveItem] = useState(-1)

  const onItemMouseDown = index => {
    setActiveItem(index)
  }

  const onItemMouseUp = index => {
    if (isMobile) return
    setActiveItem(-1)
  }

  return (
    <>
      <motion.div
        layout
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5"
        onScroll={onScroll}
      >
        <AnimatePresence>
          {items.map((item, i) => {
            return (
              <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ ease: "easeOut", duration: 0.3 }}
                layout
                key={item.id}
                className="relative"
              >
                <PhotoGridItem
                  item={item}
                  active={activeItem === i}
                  onItemMouseDown={() => onItemMouseDown(i)}
                  onItemMouseUp={() => onItemMouseUp(i)}
                />
              </motion.div>
            )
          })}
        </AnimatePresence>
      </motion.div>
    </>
  )
}

export default PhotoGrid
